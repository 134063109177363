var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-0 p-0 w-full h-full"},[_c('ContactsAppBar',{attrs:{"headers":_vm.headers},on:{"search-update":function($event){return _vm.updateSearch($event)},"sort-update":function($event){return _vm.updateSort($event)},"filter-all":function($event){return _vm.filterContacts('all')},"filter-favorites":function($event){return _vm.filterContacts('favorites')}}}),(_vm.$_breakpoints_desktop)?_c('v-data-table',{staticClass:"contacts",attrs:{"fixed-header":"","search":_vm.search,"items":_vm.items || [],"loading":_vm.loading,"headers":_vm.headers,"footer-props":_vm.dataTableFooterProps,"options":_vm.dataTableOptions},on:{"update:options":function($event){_vm.dataTableOptions=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.fetchDataError)?_c('v-btn',{staticClass:"contacts__refresh-btn",attrs:{"color":"error","outlined":""},on:{"click":function($event){return _vm.$_contacts_getContacts()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" $refresh ")]),_vm._v(" "+_vm._s(_vm.$t('button.Refresh'))+" ")],1):_c('EmptyContent',{attrs:{"title":_vm.$t('errors.contacts_empty')}})]},proxy:true},{key:"no-results",fn:function(){return [_c('p',{staticClass:"contacts__message"},[_vm._v(" "+_vm._s(_vm.$t('data.No results'))+" ")])]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('Avatar',{staticClass:"mr-2",attrs:{"initials":item.initials,"email":item.email,"size":40,"color":"secondary"}}),(item.name)?_c('Tooltip',{attrs:{"text":item.name,"disable-tooltips":false,"activator-width-limit":_vm.activatorWidthLimit}}):_c('span',{staticClass:"font-bold text-gray-400"},[_vm._v(" "+_vm._s(_vm.$t('user.Unknown'))+" ")])]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('Tooltip',{attrs:{"text":item.number,"disable-tooltips":false,"activator-width-limit":_vm.activatorWidthLimit}})]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('Tooltip',{attrs:{"text":item.email,"disable-tooltips":false,"activator-width-limit":_vm.activatorWidthLimit}})]}},{key:"item.mobile",fn:function(ref){
var item = ref.item;
return [_c('Tooltip',{attrs:{"text":item.mobile,"disable-tooltips":false,"activator-width-limit":_vm.activatorWidthLimit}})]}},{key:"item.company_name",fn:function(ref){
var item = ref.item;
return [_c('Tooltip',{attrs:{"text":item.company_name,"disable-tooltips":false,"activator-width-limit":_vm.activatorWidthLimit}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('AudioCallBtn',{staticClass:"ml-auto",attrs:{"btn-color":"transparent","icon-color":"accent","contact":item}}),_c('VideoCallBtn',{attrs:{"btn-color":"transparent","icon-color":"accent","contact":item}})]}}],null,true)}):_c('ContactsList',{attrs:{"items":_vm.items || [],"search":_vm.search,"sort":_vm.sort,"current-page":_vm.dataTableOptions.page,"items-per-page":_vm.dataTableOptions.itemsPerPage,"loading":_vm.loading},on:{"update-page":function($event){return _vm.updatePage($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }